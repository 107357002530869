@charset "utf-8";
@import "../ui/color";

.main-container {
    overflow-x: hidden;
    margin-top: 62px;
}

// 修饰图片
.img-decorating {
    position: absolute;
    z-index: -1;
}

.block {
    padding: 54px 0 0 0;
    
    .col-wrap {
        box-sizing: border-box;
    }
    .row{
        width: 100%;
        height: 343px;
        position: relative;
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        .left-pic{
            left: 0px;
            position: absolute;
            width: 60%;
            height:360px;
        }
        .right-pic{
            position: absolute;
            text-align: center;
            width: 38%;
            left: 62%;
            height:360px;
        }
    }
}

.block-head {
    position: relative;
    padding-bottom: 53px;
    &::before {
        position: absolute;
        bottom: 0;
        left: 50%;
        content: ' ';
        width: 62px;
        height: 4px;
        // background-color: #5D77FF;
        transform: translate(-50%, 0);
    }
    h2 {
        font-size: 30px;
        color: #161411;
        line-height: 1;
        text-align: center;
        font-size:24px;
        font-family:PingFang SC;
        font-weight:500;
    }
}

.banner {
    position: relative;
    min-height: 440px;
    max-height: 500px;
    background:rgba(243,245,250,1);

    overflow: hidden;

    .pic {
        position: absolute;
        top: 0;
        left: 50%;
        height: 100%;
        width: 582px;   
        height: 368px;
    }

    .col-wrap {
        height: 100%;
    }

    .text {
        position: absolute;
        top: 52%;

        transform: translate(0, -50%);

        h2 {
            margin-bottom: 40px;

            font-size:30px;
            font-family:PingFang SC;
            font-weight:500;
            color:rgba(44,45,65,1);
        }

        h3 {
            margin-bottom: 60px;
            font-size:14px;
            font-family:PingFang SC;
            font-weight:400;
            color:rgba(53,55,75,1);
            line-height:28px;
        }

        .tag {
            margin-bottom: 50px;
            width: 40px;
            height: 2px;
            background:rgba(41,110,237,1);
        }

        a {
            text-align: left;
            font-size:14px;
            font-family:PingFang SC;
            font-weight:400;
            color: rgba(255,255,255,1);
            background: #2A6BEE;
            padding: 5px 21px 5px;
        }
    }
}
.block-box-1{
    min-height: 440px;
    background:white;
    .title{
        font-size:14px;
        font-family:PingFang SC;
        font-weight:400;
        color:#161411;
        margin-bottom: 71px;
    }
    .btn-box{
        color: white;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .item-btn{
            height: 60px;
            margin-bottom: 10px;
            width: 100px;
            width: 42%;
            background: #fff;
            box-shadow: 0px 25px 50px 0px rgba(27,27,126,0.06);
            font-size: 14px;
            line-height: 60px;
            color: #637178;
            border: 1px solid #cbcbd2;
            text-align: left;
            padding-left: 36px;
            .item-btn-img{
                vertical-align: middle;
            }
        }
    }
}
.block-box-2{
    // min-height: 1710px;
    background: #EBEFF6;
    .row{
        height: 360px;
        .left-pic{
            left: 0px;
            width: 50%;
            .box-left{
                h2{
                    font-size:14px;
                    font-family:PingFang SC;
                    font-weight:400;
                    color:rgba(142,144,159,1);
                    margin-top: 17px;
                    margin-bottom: 40px;
                }
                h3{
                    font-size:14px;
                    font-family:PingFang SC;
                    font-weight:400;
                    color:rgba(22,20,17,1);

                }
                padding-left: 60px;
                padding-top: 86px;
                .top-box{
                    width: 80%;
                    font-size:24px;
                    font-family:PingFang SC;
                    font-weight:500;
                    color:rgba(26,29,60,1);
                    border-bottom: 1px solid #D1D2D9;
                    height: 50px;
                    &::before {
                        position: absolute;
                        top: 135px;
                        content: ' ';
                        width: 30px;
                        height: 2px;
                        background-color: #1A1D3C;
                    }

                }

            }
        }
        .right-pic{
            width: 50%;
            left: 50%;
            .box-right{
                padding: 17px 53px;
                margin: 24px 40px;
                background: #DEE2EB;
            }
        }
        .tag-left{
            width: 50%;
            left: 50%;
        }
        .tag-right{
            left: 0px;
            width: 50%;
        }
    }
    .row-white{
        background: white;
    }
}
.block-box-3{
    .box-3-h2{
        font-size:14px;
        font-family:PingFang SC;
        font-weight:400;
        color:rgba(22,20,17,1);
        margin-bottom: 52px;
    }
    .row{
        height: 200px;
        .item{
            box-sizing: border-box;
            width: 32.5%;
            text-align: center;
            img{
                width: 100%;
            }
        }
    }
}
.block-box-4{
    min-height: 440px;
    .row{
        h2{
            margin-bottom: 32px;
            font-size:18px;
            font-family:PingFang SC;
            font-weight:500;
            color:rgba(22,20,17,1);
        }
        h3{
            font-size:14px;
            font-family:PingFang SC;
            font-weight:400;
            color:rgba(22,20,17,1);
        }
        height: 248px;
        .left-pic{
            left: 50%;
            width: 50%;
        }
        .right-pic{
            left: 0px;
            width: 50%;
        }
        img{
            position: absolute;
            left: 0px;
        }
    }
}
.block-box-5{
    min-height: 468px;
    background: #F3F5FA;
    .row{
        .item-1{
            border-top: 1px solid #506DFA;
            width: 250px;
            .item-1-tag{
                line-height: 36px;
                height: 36px;
                border-bottom: 1px solid #8C8D97;
            }
            .number{
                margin-left: 1px;
                text-align: center;
                display: inline-block;
                background: #2A6BEE;
                color: white;
                width: 13px;
                height: 12px;
                line-height: 12px;
                margin-right: 10px;
            }
            .number-tag{
                background: #626475;
            }
        }
        .item-2{
            border-top: 1px solid #626475;
            width:500px;
            h2{
                margin-top: 26px;
                font-size:14px;
                font-family:PingFang SC;
                font-weight:400;
                color:rgba(22,20,17,1);
            }
        }
        .item-3{
            img{
                margin-top: 26px;
            }
            border-top: 1px solid #626475;
            width:308px;
        }
    }

}